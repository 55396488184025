
.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.containerTagGroup {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex: 1;
    overflow-y: scroll;
    padding-top: 8px;
    padding-bottom: 8px;
}

