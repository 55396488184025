.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: solid 1px #e0e0e0;
}

.containerHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.headerCancel {
    padding: 14px 26px;
    color: #999999;
    font-weight: 400;
    font-size: 18px;
}

.headerConfirm {
    padding: 14px 26px;
    color: #1677ff;
    font-weight: 400;
    font-size: 18px;
}

.colorBlock {
    color: white;
    width: 64px;
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
}

.colorBlockName {
    color: #fff;
    display: block;
    font-weight: bold;
}


.bodyContainer {
    width: 100%;
    height: 400px;
    overflow-y: scroll;
    padding-top: 8px;
    padding-bottom: 8px;
}

.colorGroupContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-self: flex-start;
}

.colorGroupTitle {
    font-weight: bold;
    font-size: 16px;
    margin-top: 16px;
    margin-bottom: 8px;
    display: block;
    color: #282c34;
}

.colorGroupItemSpace {
    width: calc(100vw - 64px);
    max-width: calc(100vw - 64px);
}
