
.container {
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10%;
    margin: auto;
}

.title {
    display: block;
    font-size: 26px;
    text-align: center;
    margin-bottom: 82px;
}

.inputLogin {
    width: calc(100% - 36px);
    height: 50px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 0 18px;
}

.inputPassword {
    width: calc(100% - 36px);
    height: 50px;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    margin-bottom: 20px;
    padding: 0 18px;
}

.btnLogin {
    margin-top: 46px;
    width: 100%;
}

.containerExtra {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-top: 0px;
}

.containerExtraForgetPassword {
    font-size: 14px;
    color: #999;
}

.containerExtraShowPassword {
    color: #999;
    --icon-size: 14px;
    --font-size: 12px;
}

.txtUpdateServerAddress {
    position: fixed;
    bottom: 16px;
    left: 0;
    right: 0;
    text-align: center;
    color: lightgray;
}
