.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.containerBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
}

.containerHeader {
    background: #FFF;
    display: flex;
    width: calc(100% - 32px);
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border-bottom: 1px solid #e8e8e8;

    .hint {
        margin-left: 16px;
        font-size: 16px;
    }

}

.containerSkeleton {
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    padding: 32px 16px;
}

.containerGraph {
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    padding: 32px 16px;

    .section {
        width: calc(100% - 32px);
        display: flex;
        flex-direction: column;
        padding: 8px 16px;
        justify-content: flex-start;
        align-items: flex-start;

        .title {
            color: #000;
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 6px;
        }

        .paragraph {
            display: block;
            color: #000;
            font-size: 16px;
            margin-bottom: 4px;
        }

    }

}

