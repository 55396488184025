
.container {
    width: calc(100% - 32px - 32px);
    margin: 6px 16px;
    border-radius: 8px;
    border: solid 1px #d9d9d9;
    background: #fff;
    padding: 8px 16px;
}

.name {
    font-weight: bold;
    font-size: 14px;
    color: #000;
}

.time {
    font-weight: normal;
    font-size: 12px;
    margin-top: 4px;
    color: #999999;
}

.money {
    margin-left: 16px;
    font-weight: bold;
    color: #1677ff;
    font-size: 14px;
}

.moneyOutcome {
    color: #ff2a51;
}

.moneyIncome {
    color: #05ba45;
}

.tagContainer {

}

