
.container {
    background-color: #fff;
    width: calc(100% - 24px);
    justify-content: center;
    align-items: center;
}

.titleContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;

    .title {
        font-size: 18px;
        font-weight: bold;
        color: #000;
    }

    .subtitle {
        margin-top: 4px;
        font-size: 13px;
        color: #c0c0c0;
    }

}
