.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.containerBody {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
}

.form {
    width: 100%;
}

.result {
    background-color: transparent;
}

.btnDelete {
}

.btnConfirm {
}

.textNotEditable {
    font-size: 12px;
    color: #c0c0c0  ;
}

.formFooter {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.textDanger {
    color: red;
}

.fieldHint {
    width: calc(100% - 24px);
    margin: 6px 12px;
    align-items: center;
    color: #999999;
}

.formTimeDate {
    background-color: #f2f5f7;
    padding: 4px 6px;
    border-radius: 4px;
}

.formTimeTime {
    margin-left: 8px;
    background-color: #f2f5f7;
    padding: 4px 6px;
    border-radius: 4px;
}
