.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.containerBody {
    overflow-y: scroll;
}

.result {
    background-color: transparent;
}

.btnDelete {
}

.btnConfirm {
}

.textNotEditable {
    font-size: 12px;
    color: #c0c0c0  ;
}

.formFooter {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.textDanger {
    color: red;
}

.fieldHint {
    width: calc(100% - 24px);
    margin: 6px 12px;
    align-items: center;
    color: #999999;
}
