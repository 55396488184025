.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.containerBody {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    overflow-y: scroll;
}

.containerForm {
    width: 100%;
}

.containerAction {
    width: 100%;
    --gap: 16px;
}

.colorPreview {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 2px solid #999;
}

