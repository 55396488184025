.container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-bottom: solid 1px #e0e0e0;
}

.containerHeader {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.btnConfirm {
    padding: 14px 26px;
    color: #1677ff;
    font-weight: 400;
    font-size: 18px;
}

.btnCancel {
    padding: 14px 26px;
    color: #999999;
    font-weight: 400;
    font-size: 18px;
}

.picker {
    height: 300px;
}
