
.container {
    width: calc(100% - 32px);
    margin: 8px 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.iconLoading {
    width: 18px;
    height: 18px;
}

.indicator {
    width: 12px;
    height: 20px;
    background-color: #1677ff;
    border-radius: 4px;
}

.title {
    margin-left: 16px;
    flex: 1;
    font-size: 22px;
    font-weight: bold;
    color: #000;
}
