
.scaffold {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 10%;
    width: calc(100% - 20%);
    height: 100%;
}

.input {
    border: lightgray solid 1px;
    padding: 8px 16px;
    width: calc(100% - 32px);
    border-radius: 6px;
}

.btn {
    margin-top: 10%;
}

