.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
}

.containerBody {
    width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
    overflow-y: scroll;
}

.containerBillList {
    width: calc(100% - 32px);
    justify-items: flex-start;
    align-items: center;
    display: flex;
    gap: 16px;
    flex-direction: row;
    overflow-x: scroll;
    padding: 0 16px;
    min-height: calc(84px + 10px);
}

.containerRecordList {
    width: 100%;
    flex: 1;
    justify-items: flex-start;
    align-items: flex-start;
}

.recordListLoadMore {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #1677ff;
}
