.container {
    width: calc(100% - 32px);
    margin: 0 16px;
    padding: 12px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    border: solid 1px #d9d9d9;
    background: #fff;
}

.text {
    flex: 1;
    font-size: 14px;
    color: #282c34;
    font-weight: bold;
    margin-left: 12px;
}

.icon {
    width: 28px;
    height: 28px;
    margin-left: 12px;
}
