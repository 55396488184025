.container {
    width: 48px;
    height: 68px;
    border-radius: 6px;
    background-color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 8px;
    transition-duration: 300ms;
    min-width: 48px;
    box-shadow: rgba(0,0,0,0.2) 0 0 4px;
    color: #282c34;
    margin: 6px 0;
}

.selected {
    background-color: #1677ff;
    box-shadow: rgba(0,0,0,0.1) 0 0 6px;
    color: #fff;
}
