
.container {
    width: calc(100% - 64px);
    margin: 8px 16px;
    background: #fff;
    border-radius: 4px;
    box-shadow: #d9d9d9 0 0 6px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 16px 16px
}

.title {
    font-weight: 400;
    font-size: 16px;
    display: block;
    flex: 1;
}

.tagGroupTitleContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
    align-items: center;
}

.divider {
    width: 100%;
    height: 1px;
    background: #d9d9d9;
    margin-top: 16px;
    margin-bottom: 8px;
}

.colorTagGroupPreview {
    width: 18px;
    height: 18px;
    margin-right: 16px;
    border-radius: 9px;
}

.tagGroupContainer {
    margin-top: 8px;
}

.tagGroupAddIcon {
    font-weight: bold;
    margin-right: 2px;
    color: #fff;
}
