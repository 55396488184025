.container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}


.userInfoContainer {
    margin-top: 20%;
    margin-bottom: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.userInfoName {
    margin-top: 18px;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
}


.userInfoSection {
    width: 86%;
    margin-bottom: 12px;
}

.userInfoLogout {
    width: 86%;
    margin: 16px 10%;
}
