.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 2px 6px;
    height: 18px;
}

.name {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
}


