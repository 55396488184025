
.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.tagGroup {
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 8px;
    width: calc(100% - 16px);
    max-width: 450px;
    align-self: center;
}

.tagGroupChildren {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    flex-wrap: wrap;
}