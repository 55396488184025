.container {
    width: 48px;
    height: 68px;
    border-radius: 6px;
    background: white;
    font-size: 12px;
    font-weight: bold;
    padding: 8px;
    transition-duration: 300ms;
    min-width: 48px;
    box-shadow: rgba(0,0,0,0.2) 0 0 4px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 6px 0;
}

.text {
    margin-top: 6px;
    font-size: 12px;
    color: #282c34;
}
