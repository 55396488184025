.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: calc(100% - 32px);
    height: 60px;
    background-color: #FFF;
    margin: 8px 16px;
    box-shadow: #e0e0e0 0 0 4px;
    border-radius: 6px;
}

.icon {
    color: #999999;
}

.title {
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    margin-left: 10px;
}
